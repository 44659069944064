import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import _ from "lodash";
import React, { memo, useRef, useState } from "react";
import {
  Keyboard,
  Pressable,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { colors } from "theme";
import useStyles from "./styles.css";
import { InputProps2 } from "./types";

function TextInput02(props: InputProps2) {
  const styles = useStyles();
  const textRef = useRef<any>();
  const [isFocus, setFocus] = useState(false);
  const { label, onChangeText, error, hasPassword, hasReset, hasCalendar, editable = true } = props;
  const [showPass, setShowPass] = useState(!!hasPassword);
  const errLabelStyle = error ? { color: colors.red } : {};
  const errBorderStyle: any = error && {
    borderColor: colors.red,
  };
  const multilineStyle = props.multiline ? 
  {
    height: (props.inputStyle as any)?.height ?? 120
  } : { }
  //useImperativeHandle(props.inputRef, () => ({  checkFocused: () => isFocus, }));
  return (
    <View ref={props.inputRef} onLayout={props.onLayout} style={[styles.marginTop20, props.style]}>
      {!!label && (
        <View style={styles.v_label}>
          <Text numberOfLines={props.numberOfLines} style={[styles.label, props.labelStyle, errLabelStyle]}>
            {props.label}
          </Text>

          {_.isEmpty(error) ? null : (
            <Text style={[styles.txtError]}>{error}</Text>
          )}
        </View>
      )}
      <Pressable
        onPress={() => textRef.current.focus()}
        onFocus={() => textRef.current.focus()}
        style={[styles.border, multilineStyle,  {  }, !label && { marginTop: 0 }, props.borderStyle, errBorderStyle]}
      >
        <TextInput
          ref={textRef}
          style={[
            styles.input, 
            props.multiline && styles.inputPadding, 
            props.inputStyle
          ]}
          autoComplete="off"
          autoCorrect={false}
          value={props.value}
          editable={editable}
          multiline={props.multiline}
          secureTextEntry={showPass}
          maxLength={props.maxLength}
          onBlur={(e) => {
            props.onBlur && props.onBlur(e);
            setFocus(false);
          }}
          onFocus={(e) => {
            props.onFocus && props.onFocus(e);
            editable && setFocus(true);
          }}
          keyboardType={props.keyboardType}
          placeholder={isFocus ? "" : props.placeholder}
          placeholderTextColor={colors.grayPrimary}
          onSubmitEditing={Keyboard.dismiss}
          onChangeText={onChangeText}
          aria-disabled={!editable}
          onKeyPress={props.onKeyPress}
        />
        {hasPassword && (
          <TouchableOpacity
            onPress={() => setShowPass(!showPass)}
            style={styles.btn_icon}
          >
            <Icon
              name={showPass ? "eye-off" : "eye"}
              color={colors.grayPrimary}
              size={24}
            />
          </TouchableOpacity>
        )}
        {hasReset && (
          <TouchableOpacity 
            onPress={props.onPress}
            style={styles.btn_icon_refresh}
          >
            <View style={{flexDirection: "row", alignItems: "center"}}>
              <Icon
                name={"refresh"}
                color={colors.grayPrimary}
                size={24}
              />
              <Text style={styles.refreshText}> 
                Refresh
              </Text>
            </View>
          </TouchableOpacity>
        )}

        {hasCalendar && (
          <TouchableOpacity 
            onPress={props.onPress}
            style={styles.btn_icon_calendar}
          >
            <View style={{flexDirection: "row", alignItems: "flex-end"}}>
              <Icon
                name={"calendar-blank-outline"}
                color={colors.black}
                size={24}
              />
            </View>
          </TouchableOpacity>
        )}
        
      </Pressable>
    </View>
  );
}

export default memo(TextInput02);
