import React, { useCallback, useContext, useState } from "react";
import { ScrollView } from "react-native-gesture-handler";
import Animated, {
  useSharedValue,
  withTiming,
  Easing,
  useAnimatedStyle,
} from "react-native-reanimated";
import colors from "@assets/colors";

interface CustomeModalProviderProps {
  children: any;
}

interface ContextValue {
  onHideModal: () => void;
  onShowModal: (component: React.ReactElement) => void;
}

export const CustomModalContext = React.createContext<ContextValue>({} as ContextValue);

const CustomeModalProvider = ({ children }: CustomeModalProviderProps) => {
  const [customComponent, setCustomComponent] = useState<React.ReactElement>(null as any);
  
  const fadeInOpacity = useSharedValue(0);

  const fadeIn = () => {
      fadeInOpacity.value = withTiming(1, {
          duration: 200,
          easing: Easing.linear,
      });
  };

  const fadeOut = () => {
      fadeInOpacity.value = withTiming(0, {
          duration: 200,
          easing: Easing.linear,
      });
  };

  const animatedStyle = useAnimatedStyle(() => {
      return {
          opacity: fadeInOpacity.value, // Use the value directly
      };
  });

  const onShowModal = useCallback((component: React.ReactElement) => {
    setCustomComponent(component)
    fadeIn()
  }, [])

  const onHideModal = useCallback(() => {
    fadeOut()
    setTimeout(() => setCustomComponent(null as any), 250);
  }, [])

  return (
    <CustomModalContext.Provider value={{ onHideModal, onShowModal }}>
      {children}
      {customComponent &&
        <Animated.View style={[{ zIndex: 999, justifyContent: 'center', position: 'absolute', width: '100%', height: '100%', backgroundColor: colors.blurBG3}, animatedStyle]}>
          <ScrollView>
            {customComponent}
          </ScrollView>
        </Animated.View>
      }
    </CustomModalContext.Provider>
  );
};

export default CustomeModalProvider;
