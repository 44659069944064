import { fontAssets } from "@assets/fonts";
import { imageAssets } from "@assets/images";
import { GOOGLE_TAG } from "@env";
import Splash from "@src/components/loading";
import { install } from "ga-gtag";
import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { enableFreeze, enableScreens } from "react-native-screens";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BreakpointsProvider } from "rn-responsive-styles";
import AuthProvider from "./AuthProvider";
import store, { persistor } from "./ducks/store";
import AppNavigator from "./navigators/AppNavigator";
import ErrorModal from "./components/modals/errorModal";
import CustomeModalProvider from "./components/modals/custom-modal";


enableFreeze(true);
enableScreens(true);
install(GOOGLE_TAG); 

const App = () => {
  const [loaded, setLoaded] = React.useState(false);
  const handleLoadAssets = async () => {
    try {
      await Promise.all([...imageAssets, ...fontAssets]);
    } catch (e) {
      console.warn(e);
    } finally {
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    handleLoadAssets();
  }, []);

  if (!loaded) {
    return <Splash />;
  }

  return (
    <Provider store={store}>
        <PersistGate loading={<Splash />} persistor={persistor}>
          <BreakpointsProvider breakpoints={[1680, 1025, 768, 540]}>
            <SafeAreaProvider>
              <AuthProvider>
                <CustomeModalProvider>
                  <AppNavigator />
                </CustomeModalProvider>
              </AuthProvider>
            </SafeAreaProvider>
          </BreakpointsProvider>
        </PersistGate>
        <ErrorModal />
    </Provider>
  );
};

export default App;
