import _ from "lodash";
import React, { memo } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { colors } from "theme";
import useStyles from "./styles.css";
import { InputProps3 } from "./types";

function TextInput03(props: InputProps3) {
  const styles = useStyles();
  const { label, error } = props;
  const errLabelStyle = error ? { color: colors.red } : {};

  return (
    <View style={[styles.marginTop20, props.style]}>
      <View style={styles.v_label}>
        {!!label && (
          <Text style={[styles.label, props.labelStyle, errLabelStyle]}>
            {props.label}
          </Text>
        )}
        {_.isEmpty(error) ? null : (
          <Text style={[styles.txtError]}>{error}</Text>
        )}
      </View>
      <TouchableOpacity
        activeOpacity={1}
        onPress={props.onPress}
        disabled={!props.editable}
        style={[styles.t3_border, props?.borderStyle ?? {}]}
      >
        <Text
          style={[
            styles.t_input,
            !props.value && { color: colors.placeholder },
            props.inputStyle,
          ]}
        >
          {props.value || props.placeholder}
        </Text>
        {typeof props.renderIcon === "function" && props.renderIcon(props)}
      </TouchableOpacity>
    </View>
  );
}

export default memo(TextInput03);
